import React, { useState, useEffect } from 'react';
import style from "./CountDown.module.css";

const CountdownTimer = () => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  function calculateTimeLeft() {
    const now = new Date();
    const currentMonth = now.getMonth();
    const nextMonth = currentMonth === 11 ? 0 : currentMonth + 1;
    const year = currentMonth === 11 ? now.getFullYear() + 1 : now.getFullYear();

    // Calculate the first day of the next month
    const firstDayNextMonth = new Date(year, nextMonth, 1);
    const lastSevenDays = new Date(firstDayNextMonth.getTime() - 7 * 24 * 60 * 60 * 1000);

    if (now < lastSevenDays) {
      return {}; // Timer is not active
    }

    const difference = firstDayNextMonth - now;

    let timeLeft = {};

    if (difference > 0) {
      const seconds = Math.floor((difference / 1000) % 60);

      // Check if seconds are at 0, and set them to 59 (starting the next minute)
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: seconds === 0 ? 59 : seconds,
      };
    }

    return timeLeft;
  }

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <React.Fragment key={interval}>
        <div className={style.countNum}>
          <p>{timeLeft[interval]}</p>
          <span>{interval}</span>
        </div>
        {interval === "seconds" ? "" : <b>:</b>}
      </React.Fragment>
    );
  });

  return (
    <>
      {timerComponents.length ? (
        <div className={style.countdown}>
          <p>Time left to get this month's EDventure for FREE!</p>
          <div className={style.countdownDis}>
            {timerComponents.length ? timerComponents : <span>Time's up!</span>}
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default CountdownTimer;
