import React from "react";
import style from "./Hub.module.css"
import { Link } from "react-router-dom";

const Hub = (props) =>{
  const getNextMonth = () => {
    const date = new Date();
    const nextMonthIndex = (date.getMonth() + 1) % 12;
    const months = [
      'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
    ];
    return months[nextMonthIndex];
  };
  
  const nextMonth = getNextMonth();

    return(
   
         <div className={style.hubBck}>
          
      <div className={` ${style.hubNav}`}>
        <p>{props.hub.nav}</p>
      </div>
        <div className="subContainer">
      <div className={style.hubHeader}>
            <h1>{props.hub.header}</h1>
            <p>All members of both Cub Club & Zoologist Club will be able to
            see what EDventures ships next in the Animal of the Month Hub.</p>
            <p>Do you want to see a specific animal in your My ZOO Box?Please email <a href="mailto:support@myzoobox.com">support@myzoobox.com</a> with subject line <br/><span>“Proposal About Animal of The Month”</span></p>
            <h3>The following EDventures start shipping</h3>
            <h3>In the first two weeks of {nextMonth}.</h3>
            </div>
            {props.hub.box.map(((item,i) =>{
                return(
                    <div className={`${style.hubDis} ${style[item.reverse]}`}>
                    <div className={style.hubContent}>
                         <img src={item.icon}/>
                         <p>{item.question}</p>
                         <h3>{item.name}</h3>
                         {/* {item.link ?  <span>{item.description}</span> : "" } */}
                       
                         {item.link ?     <Link to={item.link} className={i === 0 ? style.btnColor : ""} >{item.linkText}</Link> : <b></b>}
                     
                    </div>
                    <div className={style.hubImage}>
                      <img src={item.image}/>
                    </div>
             </div>
                )
            }))}
         
        </div>

         </div>
    )
}

export default Hub;