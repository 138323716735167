import React, { useState } from "react";
import style from "./Newsletter.module.css"
const Newsletter = (props) =>{
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [show,setShow] = useState(false)
    const acEmail = document.querySelector("#email")
    const acButton = document.querySelector("#_form_21_submit")
    const handleInputChange = (e) => {
        setEmail(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!email) {
            setError('Email is required');
            return;
        }
        if (!validateEmail(email)) {
            setError('Please enter a valid email address');
            return;
        }else{
            setShow(true)
            console.log("Aaa")
            acEmail.value = email
            acButton.click()
        }

        // If validation passes, you can submit the form
        // For now, let's just log the email
        console.log(email);

        // Clear the email field and error message
        setEmail('');
        setError('');
    };

    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };


    console.log(acEmail,acButton)
    return(
        <div className={`${style.newsletter} ${style[props.increase]}`}>
            <div className="container">
                 <div className={show ? style.showMessage : style.hideMessage }>
                 <h1>Sign up for our newsletter</h1>
                  <p className={style.newsText}>Get free DIY printables, educational materials, early access to new products, promos, and more!</p>

                  <form  onSubmit={handleSubmit}>
                    <div>
                    <input
                    type="email"
                    name="email"
                    value={email}
                    onChange={handleInputChange}
                    placeholder="Enter your email address"
                />
                        <button>Subscribe</button>
                    </div>
                    {error && <p style={{ color: 'white' }}>{error}</p>}
                  </form>
                  <div class="_form_21"></div>
                 </div>
                  <div  className={`${style.submitMessage} ${show ?  style.hideMessage :style.showMessage}`}>
                    <h1>Yay! You're all signed up.😊</h1>
                  </div>
            </div>
        </div>
    )
}

export default Newsletter;