import React from "react";
import Plans from "./Plans";
import { Helmet } from "react-helmet";


const PlansGiftZoo = () =>{
 
  const box = [
    {
        ribbonOne:"Flexible",
        ribbonTwo:"Save $9.95",
        plan:"Monthly EDventures",
        text:"All the flexibility and all the fun included. Paid Monthly.",
        price:"$37.95/month",
        checkout:"Monthly-zoo",
        button:"Start a monthly EDventure",
        code:"",
        info:"Didn’t love it? You can cancel anytime.",
   
      
      },
      {
        ribbonOne:"Most Popular",
        colorBck:"colorOne",
        border:"colorBorder",
        ribbonTwo:"Save $65.70",
        plan:"6 Months of EDventures",
        text:"Add fun to your kids science, biology, zoology and reading classes. Paid Monthly.",
        price:"$36.95/month",
        checkout:"6Months-zoo",
        button:"Start a 6 month EDventure",
        code:`First Box Free with code `,
        info:"Continues to renew monthly after the initial 6 months of EDventures",
        infoShow:"treu"
      
      },
      {
        ribbonOne:"Best Value",
        ribbonTwo:"Save $143.40",
        colorBck:"colorTwo",
        border:"colorBorder",
        plan:"12 Months of EDventures",
        text:"Use My ZOO Box as a core part of your kids curriculum. Paid Monthly.",
        price:"$35.95/month",
        checkout:"12Months-zoo",
        button:"Start a 12 month EDventure",
        code:`First Box Free with code `,
        info:"Continues to renew monthly after the initial 12 months of EDventures",
        infoShow:"treu"
       
      },
      {
        ribbonOne:"Best Value",
        ribbonTwo:"Save $143.40",
        colorBck:"colorThree",

        plan:"Monthly Mini Zoo",
        text:"A mini version of our Zoologist Club includes: Animal Friend, Non-Fiction Science Book, Postcard from the Animal, and Collectable Sticker.        ",
        price:"$23.95/month",
        checkout:"Mini-zoo",
        question:"+ Free Arctic Fox Unit Study",
        button:"Start a 12 month EDventure",
        code:``,
        info:"Didn’t love it? You can cancel anytime.",
       
      }
   ]

   const boxTwo = [
    
      {
        ribbonOne:"Most Popular",
        colorBck:"colorOne",
        border:"colorBorder",
        ribbonTwo:"Save $65.70",
        plan:"6 Months of EDventures",
        text:"Add fun to your kids science, biology, zoology and reading classes.",
        price:"$36.95/month",
        checkout:"6Prepaid-zoo",
        button:"Start a 6 month EDventure",
        code:`First Box Free with code `,
        totalPrice:"$221.70 paid upfront",
        info:"Does not Renew. Cancellation email sent after purchase",
      },          
      {
        ribbonOne:"Best Value",
        ribbonTwo:"Save $143.40",
        colorBck:"colorTwo",
        border:"colorBorder",
        plan:"12 Months of EDventures",
        text:"Use My ZOO Box as a core part of your kids curriculum.",
        price:"$35.95/month",
        checkout:"12Prepaid-zoo",
        button:"Start a 12 month EDventure",
        code:`First Box Free with code `,
       totalPrice:"$431.40 paid upfront",
       info:"Does not Renew. Cancellation email sent after purchase",
      },
    
   ]


       const giftPlans = [
        
        {
          ribbonOne:"Most Popular",
          colorBck:"colorOne",
          border:"colorBorder",
          ribbonTwo:"Save $65.70",
          plan:"6 Months of EDventures",
          text:"Add fun to your kids science, biology, zoology and reading classes. ",
          price:"$36.95/month",
          checkout:"6Prepaid-zooGift",
          button:"Start a 6 month EDventure",
          code:`First Box Free with code `,
          totalPrice:"$221.70 paid upfront",
          info:"Does not Renew. Cancellation email sent after purchase",
          prepaid:"prepaid6",
          cartImg:"/image/zoopg6.png"
        
        },          
        {
          ribbonOne:"Best Value",
          ribbonTwo:"Save $143.40",
          colorBck:"colorTwo",
          border:"colorBorder",
          plan:"12 Months of EDventures",
          text:"Use My ZOO Box as a core part of your kids curriculum. ",
          price:"$35.95/month",
          checkout:"12Prepaid-zooGift",
          button:"Start a 12 month EDventure",
          code:`First Box Free with code `,
          totalPrice:"$431.40 paid upfront",
          info:"Does not Renew. Cancellation email sent after purchase",
          prepaid:"prepaid12",
          cartImg:"/image/zoopg12.png"
         
        },
      
     ]
       const header ={
        title:"Zoologist Club Plan ",
        text:"Shipping charges apply only for the FREEBOX promo. Shipping for all future My ZOO Box EDventures will be FREE.",
        icon:"/image/icon/zoo.svg",
        remove:""
      }
      const plansImage = {
        bigImage:"/image/bigImage.webp",
        small:[
          {
            image:"/image/c3.webp"
          },
          {
            image:"/image/c2.webp"
          },
          {
            image:"/image/smallImage3.webp"
          },
          {
            image:"/image/smallImage4.webp"
          }
        ]
      }
    return(
        <>
               <Helmet>
        <title>Zoology in a Box: Wildlife Subscription Boxes for Kids</title>
        <meta name="description" content="Our Kids' Zoology Boxes are like a Behind the Scenes Zoo Adventure in a box. Perfect for animal lovers and young explorers eager to learn about the animal kingdom and conservation efforts.
" />
      </Helmet>
        <Plans  box={box} boxTwo={boxTwo}  header={header} giftPlans="giftPlans" image={plansImage} gift={giftPlans}/>
        </>
    )
}

export default PlansGiftZoo;