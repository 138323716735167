// ProductDetail.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
// import SwiperCore, { Pagination, Navigation, Autoplay } from 'swiper';
import productsData from './ProductsData';
import Testimonials from "../../component/testimonials/Testimonials";
import Helps from "../../component/helps/Helps";
import KidLove from "../../component/kidLove/kidLove";
import style from "./MainShop.module.css"
import Trusted from "../../component/trusted/Trusted";
// SwiperCore.use([Pagination, Navigation, Autoplay]);
import { FreeMode, Navigation, Thumbs } from 'swiper/modules';
function ProductDetail() {
 
  const { id } = useParams();
  const product = productsData.find(p => p.id === id);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.innerHTML = `
      window.subblyConfig = {
          apiKey: '52d2a9b4-a884-42cb-83a9-cfb67f347926',
          settings: {
              interceptProductLinks: true,
              cartCounterEl: '.subbly-cart-product-count',
              cartToggleEl: '.subbly-cart',
              cartButton: true,
          },
      };
    `;
    document.body.appendChild(script);

    const subblyScript = document.createElement('script');
    subblyScript.type = 'module';
    subblyScript.src = 'https://assets.subbly.co/cart/cart-widget.js';
    document.body.appendChild(subblyScript);

    return () => {
      document.body.removeChild(script);
      document.body.removeChild(subblyScript);
    };
  }, []);
  if (!product) {
    return <div>Product not found!</div>;
  }
  const kids = [
    {
      image:"/image/lov1.png"
    },
    {
      image:"/image/lov2.png"
    },
    {
      image:"/image/lov3.png"
    },
    {
      image:"/image/lov4.png"
    },
    {
      image:"/image/lov5.png"
    },
    {
      image:"/image/lov6.png"
    },
    {
      image:"/image/lov7.png"
    },
    {
      image:"/image/lov8.png"
    },
    {
      image:"/image/lov9.png"
    },
    {
      image:"/image/lov10.png"
    },
    {
      image:"/image/lov11.png"
    },
    {
      image:"/image/lov12.png"
    },
  ]
  const help = [
    {
      title:"Educator-Approved Learning Activities",
      description:"Each EDventure is crafted to engage preschoolers, introducing them to foundational preschool concepts with hands-on activities and FUN!",
      image:"/image/helps11.webp"
    },
    {
      class:"reverse",
      title:"Parent-approved learning materials to support early development",
      description:"As parents, it can be challenging to find quality age-appropriate learning experiences, and we are here to make it easy and convenient to discover, explore, and learn together.",
      image:"/image/helps22.webp"
    }
  ]
  const helpZoo = [
    {
      title:"Educator-Approved Learning Activities",
      description:"Each EDventure is crafted to deliver engaging learning, introducing them to a multitude of concepts with hands-on activities and FUN!",
      image:"/image/helpzoo11.webp"
    },
    {
      class:"reverse",
      title:"Parent-approved learning materials to support early development",
      description:"As parents, it can be challenging to find quality age-appropriate learning experiences, and we are here to make it easy and convenient to discover, explore, and learn together.",
      image:"/image/helpzoo22.webp"
    }
  ]
  function removeEdventure(animalName) {
    return animalName.replace(/ EDventure/g, '');
}
const AnimalName = removeEdventure(product.name);
  return (
       <> 
         <div className='container'>
            <div className={style.productDetails}>
         {/* <Swiper
              navigation
              pagination={{ clickable: true }}
              autoplay={{ delay: 5000 }}
              className={style.productCarousel}
      >
        {product.photos.map(photo => (
          <SwiperSlide key={photo}>
            <img src={photo} alt={product.name} />
          </SwiperSlide>
        ))}
      </Swiper> */}

<div className={style.productCarousel}>
      <Swiper
        style={{
          '--swiper-navigation-color': '#fff',
          '--swiper-pagination-color': '#fff',
        }}
      
        loop={true}
        spaceBetween={10}
        navigation={true}
        thumbs={{ swiper: thumbsSwiper }}
        modules={[Navigation, Thumbs]}
        className='bigImage'
      >
        {product.photos.map((photo, index) => (
           <SwiperSlide >
           <img src={photo} alt={product.name} />
         </SwiperSlide>
        ))}
            {/* <SwiperSlide >
            <img src={product.image} alt={product.name} />
          </SwiperSlide> */}
      </Swiper>
      <Swiper
        onSwiper={setThumbsSwiper}
        loop={true}
        spaceBetween={10}
        slidesPerView={4}
        freeMode={true}
        watchSlidesProgress={true}
        modules={[Navigation, Thumbs]}
        className="mySwiper smallProduct"
      >
        {product.photos.map((photo, index) => (
          <SwiperSlide key={index}>
            <img src={photo} alt={product.name} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
         <div className={style.productContent}>
          {product.age === '3-5' ?   <div className={style.productClub} style={{backgroundColor:'#D9493A'}}>{product.age === '3-5' ? <img src="/image/icon/cubb.svg" /> : <img src="/image/icon/zooo.svg" />}Ages {product.age}</div> :  <div className={style.productClub} style={{backgroundColor:'#FF7628'}}>{product.age === '3-5' ? <img src="/image/icon/cubb.svg" /> : <img src="/image/icon/zooo.svg" />}Ages {product.age}</div>}

        
          <div className={style.productType}>
            <div>
            <h1>{product.name}</h1>
            <p>{product.type}</p>
            </div>
           {product.stock && 
            <p className={style.stock}>{product.stock}</p>}
          </div>
          <h2>{product.price}</h2>
        
          <a href={product.link} className={style.cart}><img src='/image/bg.svg'/>Add To Cart</a>
          <div className={style.productDescription}>
            <h3>Learn all about the {AnimalName}</h3>
            <p>{product.description}</p>
          </div>
       
          {/* <div className={style.productInfo}>
            <div  className={style.delivery}>
              <img src='/image/truck.svg'/>
              <div>
                <p>Free Delivery</p>
                <a href='#'>Enter your Postal code for Delivery Availability</a>
              </div>
            </div>
            <div  className={style.refund}>
            <img src='/image/truck.svg'/>
              <div>
                <p>Return Delivery</p>
                <a href='#'>Free 30 days Delivery Return. Details</a>
              </div>
              </div>
          </div> */}
         </div>
            </div>
       </div>
    <Trusted/>
    {product.age === '3-5' ?
        <>
        <Helps title={
                     <>
                       How <span className={style.cubTitle}>Cub Club</span> helps 3-5 year old children learn
                     </>
                   }
       box={help}/>
       <KidLove kid={kids} color="#EA5443" title="Kids love us..." titleTwo="Over 100,000 boxes shipped globally...and counting!"/> 
        </>:  <>
        <Helps title={
  <>
    How <span className={style.zooTitle}>Zoologist Club</span> helps 5+ year old children learn
  </>
} box={helpZoo} />
        <KidLove kid={kids} color="#ff7628" title="Kids love us..." titleTwo="Over 100,000 boxes shipped globally...and counting!"/> 
        </>} 
  
     <Testimonials/>
       </>
  );
}

export default ProductDetail;
