import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { animateScroll } from "react-scroll";
import queryString from 'query-string';
import Product from './Product';
import style from "./MainShop.module.css";
import productsData from './ProductsData';

function ProductList() {
  
  const location = useLocation();
  const queryParams = queryString.parse(location.search);

  const [categoryFilter, setCategoryFilter] = useState(queryParams.category || '');
  const [ageFilter, setAgeFilter] = useState(queryParams.age || '');
  const [typeFilter, setTypeFilter] = useState(queryParams.type || '');

  useEffect(() => {
    const params = {};
    if (categoryFilter) params.category = categoryFilter;
    if (ageFilter) params.age = ageFilter;
    if (typeFilter) params.type = typeFilter;
    const newQueryString = queryString.stringify(params);
    // Replace the current query string in the URL without reloading the page
    window.history.replaceState({}, '', `${location.pathname}${newQueryString ? '?' + newQueryString : ''}`);
  }, [categoryFilter, ageFilter, typeFilter, location.pathname]);

  const filteredProducts = productsData.filter(product => {
    return (
      (!categoryFilter || product.category === categoryFilter) &&
      (!ageFilter || product.age === ageFilter) &&
      (!typeFilter || product.type === typeFilter)
    );
  });

  const areFiltersCleared = () => {
    return !categoryFilter && !ageFilter && !typeFilter;
  };

  return (
    <div className='container'>
      <div className={style.shopBanner}>
        <img src='/image/shop.png' className={style.shopDes}/>
        <img src="/image/storeff.png " className={style.shopMob}/>
        <h2>Discover & Shop Popular <br/> My ZOO Box EDventures</h2>
      </div>
      <h2 className={style.filterHeading}>Search by</h2>
      <div className={style.filter}>
        <div className={style.filterDis}>
          <div>
            <select value={categoryFilter} onChange={e => setCategoryFilter(e.target.value)}>
              <option value="">Category</option>
              <option value="One-time Boxes">One-time Boxes</option>
              <option value="Digital Unit Studies">Digital Unit Studies</option>
            </select>
          </div>
          <div>
            <select value={ageFilter} onChange={e => setAgeFilter(e.target.value)}>
              <option value="">Age</option>
              <option value="3-5">3-5</option>
              <option value="5-12">5-12</option>
            </select>
          </div>
          <div>
            <select value={typeFilter} onChange={e => setTypeFilter(e.target.value)}>
              <option value="">Type</option>
              <option value="African Animals">African Animals</option>
              <option value="Predators">Predators</option>
              <option value="Rainforest Animals">Rainforest Animals</option>
              <option value="Animals That Fly">Animals That Fly</option>
              <option value="Ocean Animals">Ocean Animals</option>
              <option value="North American Animals">North American Animals</option>
            </select>
          </div>
        </div>
        <div>
          {areFiltersCleared() && (
            <div className={style.filterBoxDis}>
              {productsData.map(product => (
                <Link to={`/product/${product.id}`} key={product.id} className={style.filterBox} onClick={() => animateScroll.scrollToTop()}>
                  <span className={style.productStock}>{product.stock}</span>
                  <span className={`${style.productAge} ${product.age === '3-5' ? style.cubAge : ""}`}>{product.age === '3-5' ? <img src="/image/icon/cubb.svg" /> : <img src="/image/icon/zooo.svg" />}Ages {product.age}</span>
                  <img src={product.image} alt={product.name} />
                  <div className={style.priceDis}>
                    <h3>{product.name}</h3>
                    <p>{product.price}</p>
                  </div>
                  <button className={style.boxView}>Learn More</button>
                </Link>
              ))}
            </div>
          )}
          {!areFiltersCleared() && (
             <>
              {filteredProducts.length > 0 ? (
            <div className={style.filterBoxDis}>
              
                { filteredProducts.map(product => (
                  <Link to={`/product/${product.id}${queryString.stringify(queryParams) ? '?' + queryString.stringify(queryParams) : ''}`} key={product.id} className={style.filterBox} onClick={() => animateScroll.scrollToTop()}>
                    <span className={style.productStock}>{product.stock}</span>
                    <span className={`${style.productAge} ${product.age === '3-5' ? style.cubAge : ""}`}>{product.age === '3-5' ? <img src="/image/icon/cubb.svg" /> : <img src="/image/icon/zooo.svg" />}Ages {product.age}</span>
                    <img src={product.image} alt={product.name} />
                    <div className={style.priceDis}>
                      <h3>{product.name}</h3>
                      <p>{product.price}</p>
                    </div>
                    <button className={style.boxView}>Learn More</button>
                  </Link>
                ))  }
             
            </div>
          ) 
            : (
              <h3 className={style.showMessage}>There are no products for your selected query.</h3>
            )}
             </>
          )}
        </div>
      </div>
    </div>
  );
}

export default ProductList;
