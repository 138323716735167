import React, {  useRef } from "react";
import RouteConfig from "./router";
import './App.css';
import Header from "./layout/header/Header";
import Footer from "./layout/footer/footer";
import { useLocation} from "react-router-dom";
import CountdownTimer from "./component/countdown/CountDown";

function App() {
  const sectionRef = useRef(null);
  const location = useLocation();

  const scrollToSection = () => {
    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  

    if (location.pathname === "/product-list"  || location.pathname === "/zoo-store" || location.pathname === "/your-first-edventure" || location.pathname === "/your-next-edventure"){
      const subbly = document.querySelector(".SubblyAppContainer button")
      if (subbly) {
      subbly.style.display = "block"
      }
    }else{
      const subbly = document.querySelector(".SubblyAppContainer button")
      if (subbly) {
       subbly.style.display = "none"
    }
    }

  
  return (
    <>

{/* {location.pathname === "/subscription"  ? "" :  */}
    <Header scroll={scrollToSection}/>
    <CountdownTimer/>
    {/* } */}

    <RouteConfig section={sectionRef} scroll={scrollToSection}/>
    {location.pathname === "/subscription" ? "":
    <Footer scroll={scrollToSection}/>}
    </>
  );
}

export default App;
